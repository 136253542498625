import Navbar from "./components/navbar/Navbar";
import About from "./pages/about/About";
import Cart from "./pages/cart/Cart";
import Service from "./pages/service/Service";
import Conditions from "./pages/conditions/Conditions";
import Contacts from "./pages/contacts/Contacts";
import Login from "./pages/login/Login";
import Restore from "./pages/restore/Restore";
import Edit from "./pages/edit/Edit";
import Create from "./pages/create/Create";
import Home from "./pages/home/Home";
import Product from "./pages/product/Product";
import Profile from "./pages/profile/Profile";
import Footerbar from "./components/footerbar/Footerbar";
import NavList from "./components/footerbar/NavList";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import History from "./pages/history/History";
import Profiles from "./components/profiles/Profiles";
import Orders from "./pages/orders/Orders";
import Order from "./pages/order/Order";
import Goods from "./pages/goods/Goods";
import Ordering from "./pages/ordering/Ordering";
import { ProductUsersContext } from "./components/contexts/ProductUsersContext";
import Recommendation from "./pages/recommendation/Recommendation";
import Popular from "./pages/popular/Popular";
import Registrations from "./pages/registration/Registrations";
import CategoryItems from "./pages/categoryItems/CategoryItems";
import Catalog from "./pages/catalog/Catalog";
import { CatalogContext } from "./components/contexts/CatalogContext";
import "./style.scss";

function App() {
  const Layout = () => {
    return (
      <ProductUsersContext>
        <>
          <Navbar />
          <div className="content">
            <Outlet />
          </div>
          <Footerbar />
          <NavList />
        </>
      </ProductUsersContext>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "catalog",
          element: <CatalogContext><Catalog /></CatalogContext> ,
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "registration",
          element: <Registrations />,
        },
        {
          path: "restore",
          element: <Restore />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "cart",
          element: <Cart />,
        },
        {
          path: "service",
          element: <Service />,
        },
        {
          path: "conditions",
          element: <Conditions />,
        },
        {
          path: "contacts",
          element: <Contacts />,
        },
        {
          path: "product/:id",
          element: <Product />,
        },
        {
          path: "category/:id",
          element: <CategoryItems />,
        },
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "ordering",
          element: <Ordering />,
        },
        {
          path: "recommendation",
          element: <Recommendation />,
        },
        {
          path: "popular",
          element: <Popular />,
        },
        {
          path: "profile",
          element: <Profile />,
          children: [
            {
              path: "",
              element: <Profiles />,
            },
            {
              path: "profile/history",
              element: <History />,
            },
            {
              path: "profile/edit",
              element: <Edit />,
            },
            {
              path: "profile/create",
              element: <Create />,
            },
            {
              path: "profile/orders",
              element: <Orders />,
            },
            {
              path: "profile/history/order",
              element: <Order />,
            },
            {
              path: "profile/goods",
              element: <Goods />,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}
export default App;
