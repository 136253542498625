import React, { createContext, useReducer } from "react";
import { catalogReducer } from "../reducers/CatalogReducer";

export const CustomContext = createContext();

const initialState = {
  catalogData: [],
};

export const CatalogContext = ({ children }) => {
  const [value, dispatch] = useReducer(catalogReducer, initialState);

  value.setCatalog = (data) => {
    dispatch({ type: "SET_CATALOG", payload: data });
  };

  return (
    <CustomContext.Provider value={value}>{children}</CustomContext.Provider>
  );
};
