import React, { useContext, useEffect } from "react";
import "./profiles.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CustomContext } from "../contexts/ProductUsersContext";
import { BASE_URL } from "../../utils/Constants";

import EditIcon from "../../image/menuItems/edit.svg";
import AddressIcon from "../../image/menuItems/adress.svg";
import CompanyIcon from "../../image/menuItems/company.svg";
import PhoneIcon from "../../image/menuItems/telephone.svg";
import UserIcon from "../../image/menuItems/userProfil.svg";
import ExitIcon from "../../image/menuItems/exit.svg";
import EmailIcon from "../../image/menuItems/email.svg";

const Profiles = () => {
  const {
    userPersonalData,
    setUserPersonalData,
    setUserError,
    historyOrder,
    setHistoryOrder,
  } = useContext(CustomContext);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserData = localStorage.getItem("userPersonalData");
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      setUserPersonalData(userData);
    }
  }, []);

  useEffect(() => {
    if (userPersonalData) {
      axios
        .post(`${BASE_URL}/local/API/v0.1/order/history/get/`, {
          userId: userPersonalData?.userData?.id,
        })
        .then((response) => {
          setHistoryOrder(response.data.orders);
        });
    }
  }, []);

  const logoutBtn = () => {
    axios.get(`${BASE_URL}/local/API/v0.1/user/logout/`);
    setUserPersonalData(null);
    setUserError(false);
    localStorage.removeItem("userPersonalData");
    navigate("/");
  };

  return (
    <div className="profiles">
      <div className="header">
        <h1>Профиль</h1>
        <div className="buttons">
          <button className="exitBtn" onClick={logoutBtn}>
            <img src={ExitIcon} alt="Выйти" />
            Выйти
          </button>
        </div>
      </div>
      <div className="info">
        <ProfileItem
          icon={UserIcon}
          title="Имя"
          value={userPersonalData?.userData?.fio}
        />
        <ProfileItem
          icon={EmailIcon}
          title="Email"
          value={userPersonalData?.userData?.email}
        />
        <ProfileItem
          icon={AddressIcon}
          title="Адрес доставки по умолчанию"
          value="Ул. Тестовая 1"
        />
        <ProfileItem
          icon={PhoneIcon}
          title="Номер телефона"
          value={userPersonalData?.userData?.phone}
        />
        <ProfileItem
          icon={CompanyIcon}
          title="Название компании"
          value={userPersonalData?.userData?.companyName}
        />
      </div>
      <div className="history">
        <h2>Последние покупки:</h2>
        <ul>
          {historyOrder.map((history) => (
            <li key={history.orderId}>
              {history.products.map((item) => (
                <div key={item.id} className="history-item">
                  <p>
                    {item.name} ({item.quantity} шт.)
                  </p>
                  <time>Дата заказа: {history.orderDateCreate}</time>
                </div>
              ))}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const ProfileItem = ({ icon, title, value }) => (
  <div className="profile-item">
    <img src={icon} alt={title} />
    <div className="text">
      <span className="title">{title}:</span>
      <span className="value">{value}</span>
    </div>
  </div>
);

export default Profiles;
