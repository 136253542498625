import React, { useContext, useEffect } from "react";
import "./orders.scss";
import { CustomContext } from "../../components/contexts/ProductUsersContext";
import axios from "axios";
import { BASE_URL } from "../../utils/Constants";

function Orders() {
  const { userProfile, setUserProfile } = useContext(CustomContext);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/local/API/v0.1/user/getProfilesList/`)
      .then((response) => {
        setUserProfile(response.data.data);
      });
  }, []);

  return (
    <div className="content_block">
      <div className="orders">
        <span className="title">Профили для заказов</span>
        <ul>
          <li>
            {userProfile.map((profileData) => (
              <div className="infoBox">
                <span className="name"> {profileData.name} </span>
                <div className="index">
                  <span className="inn"> ИНН/КПП: </span>
                  <span className="code">
                    {" "}
                    {profileData.inn} / {profileData.kpp}{" "}
                  </span>
                </div>
                <span className="adress">{profileData.deliveryAddress}</span>
              </div>
            ))}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Orders;
