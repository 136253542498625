import React, { useState } from "react";
import "./create.scss";
import fioIMG from "../../image/menuItems/fio.svg";
import adressIMG from "../../image/menuItems/adress.svg";
import companyIMG from "../../image/menuItems/company.svg";
import axios from "axios";
import { BASE_URL } from "../../utils/Constants";

const Create = () => {
  const [createProfile, setCreateProfile] = useState({
    profileName: "",
    isLegal: "N",
    address: "",
    inn: "",
    kpp: "",
  });
  const [errorText, setErrorText] = useState("");
  const [infoText, setInfoText] = useState("");

  const handleChangeCreate = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? (checked ? "Y" : "N") : value;

    setCreateProfile({
      ...createProfile,
      [name]: newValue,
    });
  };

  console.log(createProfile.isLegal, createProfile.profileName);

  const handleButtonCreate = () => {
    axios
      .post(`${BASE_URL}/local/API/v0.1/user/addProfile/`, {
        profileName: createProfile.profileName,
        isLegal: createProfile.isLegal,
        address: createProfile.address,
        inn: createProfile.inn,
        kpp: createProfile.inn,
      })
      .then((response) => {
        if (response.data.status === "error") {
          setInfoText("");
          setErrorText(response.data.message);
          return;
        }
        setErrorText("");
        setInfoText(response.data.message);
        setCreateProfile({
          profileName: "",
          isLegal: "N",
          address: "",
          inn: "",
          kpp: "",
        });
      });
  };
  return (
    <div className="content_block">
      <div className="create">
        <span className="title">Создать профиль</span>
        <span className="errorText">{errorText}</span>
        <span className="infoText">{infoText}</span>
        <div className="name">
          <img src={fioIMG} alt=""></img>
          <label>Название клиента/организации:</label>
          <input
            type="text"
            placeholder="Название клиента/организации"
            name="profileName"
            value={createProfile.profileName}
            onChange={handleChangeCreate}
          />
        </div>
        <div className="index">
          <img src={companyIMG} alt=""></img>
          <label>ИНН/КПП:</label>
          <input
            type="text"
            placeholder="ИНН/КПП"
            name="inn"
            value={createProfile.inn}
            onChange={handleChangeCreate}
          />
        </div>
        <div className="adress">
          <img src={adressIMG} alt=""></img>
          <label>Адрес организации:</label>
          <input
            type="text"
            placeholder="Адрес организации"
            name="address"
            value={createProfile.address}
            onChange={handleChangeCreate}
          />
        </div>
        <div className="switchLegal">
          <h5>Юридическое лицо:</h5>
          <input
            className="tgl tgl-flip"
            id="cb5"
            type="checkbox"
            name="isLegal"
            checked={createProfile.isLegal === "Y"}
            onChange={handleChangeCreate}
          />
          <label
            className="tgl-btn"
            data-tg-off="Нет"
            data-tg-on="Да"
            for="cb5"
          ></label>
        </div>
        <button onClick={handleButtonCreate}>Создать</button>
      </div>
    </div>
  );
};

export default Create;
