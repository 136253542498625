const Service = () => {
  return (
<div className="content_block">

<div className="page_title">
    <h1>Сервис</h1>
  </div>
    <p>Компания СтанкиПро предлагает к вашим услугам квалифицированных сервисных специалистов по наладке и ремонту оборудования.</p>
    <p>Если у вас шумит станок или просматриваются другие неполадки, закажите сервис у нас! Специалист фирмы проведет полную диагностику оборудования с предоставлением списка рекомендаций, как исправить проблему с минимальными затратами для вашего бизнеса.</p>
    <p>Надежность станка всегда на первом месте. Помните, что вовремя смазанные узлы станка увеличивают эксплуатационный срок оборудования!</p>
</div>
  );
};

export default Service;
