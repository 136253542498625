import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CustomContext } from "../contexts/ProductUsersContext";
import HomeIcon from "../../image/menuItems/home.svg";
import CatalogIcon from "../../image/menuItems/catalog.svg";
import BucketIcon from "../../image/menuItems/cart.svg";
import MenuIcon from "../../image/menuItems/menu.svg";
import UserIcon from "../../image/menuItems/profile.svg";
import "./navlist.scss";
import { BASE_URL } from "../../utils/Constants";

function Footerbar() {
  const { order, setUserError, userError } = useContext(CustomContext);

  return (
    <div className="navigation--list">
      <div className="navigation__content">
        <div className="navigation__item ps-toggle--sidebar1">
          <Link to={"/"}>
            <img src={HomeIcon} alt="" className="icon" />
          </Link>
          <span> Главная</span>
        </div>
        <div className="navigation__item ps-toggle--sidebar">
          <Link to={"/catalog"}>
            <img src={CatalogIcon} alt="" className="icon" />
          </Link>
          <span> Категории</span>
        </div>
        <div className="navigation__item ps-toggle--sidebar">
          <Link to={"/cart"}>
            <img src={BucketIcon} alt="" className="icon" />
          </Link>
          <span className="orderSize">{order.length}</span>
          <span> Корзина</span>
        </div>
        <div className="navigation__item ps-toggle--sidebar">
          <img src={MenuIcon} alt="" className="icon" />
          <span> Меню</span>
        </div>
        <div className="navigation__item ps-toggle--sidebar1">
          <Link to={"/login"}>
            <img src={UserIcon} alt="" className="icon" />
          </Link>
          <span> Профиль</span>
        </div>
      </div>
    </div>
  );
}

export default Footerbar;
