import React from "react";
import "./conditions.scss";

const Conditions = () => {
  return (
    <div className="content_block">
      <div className="page_title">
        <h1>Условия оплаты и доставки</h1>
      </div>
      <h2>Оплата наличными</h2>
      <p>
        Вы дожидаетесь приезда курьера и передаёте ему сумму за товар в рублях
      </p>
      <h2>Курьерская доставка</h2>
      <p>
        Вы можете заказать доставку товара с помощью курьера, который прибудет
        по указанному адресу в будние дни и субботу с 9.00 до 19.00.{" "}
      </p>
    </div>
  );
};

export default Conditions;
