import React from "react";
import "./order.scss";
import { Card } from "react-bootstrap";
import testProduct from "../../image/menuItems/testProduct.png";
import bucketBtn from "../../image/menuItems/bucketBtn.svg"

function Order() {
  return (
    <div className="order">
        <span className="mainTitle">Заказ #1</span>
        <p className="infoBox">
            <span className="name"> Клиент #1 </span>
            <div className="delivery">
                <span className="text"> Время доставки: </span>
                <time className="time"> 45 мин </time>
            </div>
            <div className="payment"> 
                <span className="method"> Способ оплаты: </span> 
                <span className="select"> Наличные </span>
            </div>
            <span className="adress"> г. Махачкала, ул. Аметхана Султана, 314 </span>
        </p>

        <span className="title">Содержимое заказа</span>

        <div className="item">
            <Card style={{ width: "18rem" }}>
                <Card.Img variant="top" src={testProduct} />
                <Card.Body>
                    <Card.Title>WoodTec PS 45S PRO</Card.Title>
                    <Card.Text>
                        <div className="priceBox">
                            <span className="price">$300</span>
                            <span className="action">$200</span>
                        </div>
                        <button className="btnBucket">
                            <img src= {bucketBtn} alt=""/>
                        </button>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    </div>
  )
}

export default Order;