import React, { useContext } from "react";
import searchImg from "../../image/menuItems/search.svg";
import "./search.scss";
import { CustomContext } from "../contexts/ProductUsersContext";

function Search() {
  const { setProducts, originalProductData } = useContext(CustomContext);

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    if (searchTerm === "") {
      setProducts(originalProductData); // восстанавливаем исходные данные
    } else {
      // выполняем фильтрацию и устанавливаем отфильтрованные данные
      const filtered = originalProductData.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setProducts(filtered);
    }
  };

  return (
    <div className="search">
      <input type="text" placeholder="Поиск" onChange={handleSearch} />
      <button>
        <img src={searchImg} alt="" />
      </button>
    </div>
  );
}

export default Search;
