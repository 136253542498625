import React from "react";
import Carts from "../carts/Carts";

function Cart() {
  return (
    <div className="content_block">
    <div className="cart">
      <Carts />
    </div>
    </div>
  );
}

export default Cart;
