import "./contacts.scss";

const Contacts = () => {
  return (
    <div className="content_block">
      <div className="page_title">
        <h1>Контакты</h1>
      </div>
      <div className="contacts">
        <div className="contact_info">
          <div>
            <ul>
              <li className="title">Адрес:</li>
              <li>г. Махачкала, ул. Булача, 17Д</li>
              <li>г. Махачкала, ул. Аметхана Султана, 314</li>
            </ul>
          </div>
          <div>
            <ul>
              <li className="title">Режим работы:</li>
              <li>Пн-Сб - 9:00 - 18:00</li>
              <li>Вс - дежурный менеджер</li>
            </ul>
          </div>
          <div>
            <ul>
              <li className="title">Отдел продаж:</li>
              <li>8 8722 777 711</li>
              <li>+7 963 423-16-93 многоканальный</li>
              <li>+7 964 018-19-00 Караева Ума</li>
              <li>+7 963 407-68-00 Арсланбекова Аида</li>
              <li>+7 963 404-87-30 Халиков Магомедхан</li>
              <li>+7 963 404-88-19 Абушев Наби</li>
            </ul>
          </div>
          <div>
            <ul>
              <li className="title">Отдел сервисного обслуживания:</li>
              <li>+7 963 404-87-48 Идрис</li>
            </ul>
          </div>
          <div>
            <ul>
              <li className="title">Реквизиты компании:</li>
              <li>ИП Арсланбекова М.Э.</li>
              <li>ИНН 054500133449</li>
              <li>ОГРН 318057100069131</li>
            </ul>
          </div>
          <div>
            <ul>
              <li className="title">E-mail:</li>
              <li>info@stanki05.ru</li>
            </ul>
          </div>
        </div>
        <div className="map">
          <iframe
            src="https://yandex.ru/map-widget/v1/?lang=ru_RU&amp;scroll=true&amp;um=constructor%3A3783de36251df335bd518c4102ac622a75acbb4a30b56c638a53d9420afcb649"
            frameborder="0"
            allowfullscreen="true"
            width="100%"
            height="460px"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
