import React, { useContext, useEffect, useState } from "react";
import "./popular.scss";
import { Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { CustomContext } from "../../components/contexts/ProductUsersContext";
import { BASE_URL } from "../../utils/Constants";

function Popular() {
  const { popularProductData, setPopularProductData, addToBacked, order } =
    useContext(CustomContext);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/local/API/v0.1/shop/products/getPopular/`)
      .then((response) => {
        if (response.length === 1) {
          setPopularProductData([response.data.data]);
          return;
        }
        setPopularProductData(response.data.data);
      });
  }, []);

  const [addedProducts, setAddedProducts] = useState([]);

  useEffect(() => {
    const addedProductIds = order.map((product) => product.id);
    setAddedProducts(addedProductIds);
  }, [order]);

  return (
    <div className="content_block">
      <div className="page_title">
        <h1>Популярные товары</h1>
      </div>
      <div className="popular">
        {popularProductData.map((product) => (
          <Card style={{ width: "18rem" }} key={product.id}>
            <Card.Img variant="top" src={product.picture} />
            <Card.Body>
              <Card.Title>
                <Link to={`/product/${product.id}`}>{product.name}</Link>
              </Card.Title>
              <Card.Text>
                <div className="priceBox">
                  {product.price === product.oldPrice ? (
                    <span className="action">{product.oldPrice}</span>
                  ) : (
                    <>
                      <span className="price">{product.oldPrice}</span>{" "}
                      <span className="action">{product.price}</span>{" "}
                    </>
                  )}
                </div>
                <button
                  className={`btnBucket ${
                    addedProducts.includes(product.id) ? "added" : ""
                  }`}
                  onClick={() =>
                    addToBacked({
                      id: product.id,
                      name: product.name,
                      price: product.price,
                      oldPrice: product.oldPrice,
                      picture: product.picture,
                      sku: product.SKU,
                    })
                  }
                  disabled={addedProducts.includes(product.id)}
                >
                  {addedProducts.includes(product.id)
                    ? "В корзине"
                    : "В корзину"}
                </button>
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Popular;
