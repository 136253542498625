import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./registration.scss";
import Email from "../../image/menuItems/email.svg";
import Lock from "../../image/menuItems/lock.svg";
import Name from "../../image/menuItems/user.svg";
import Phone from "../../image/menuItems/phone.svg";
import Eye from "../../image/menuItems/eye.svg";
import axios from "axios";
import { BASE_URL } from "../../utils/Constants";

const Registrations = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorPassword, setErrorPassword] = useState("");
  const [errorText, setErrorText] = useState("");
  const [registrationData, setRegistrationData] = useState({
    fio: "",
    email: "",
    phone: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChangeRegister = (event) => {
    setRegistrationData({
      ...registrationData,
      [event.target.name]: event.target.value,
    });
  };

  const handleButton = (event) => {
    event.preventDefault();
    if (registrationData.password.length < 6) {
      setErrorPassword("Пароль должен быть не менее 6 символов");
      return;
    }
    setErrorPassword("");
    axios
      .post(`${BASE_URL}/local/API/v0.1/user/registrate/`, {
        fio: registrationData.fio,
        email: registrationData.email,
        phone: registrationData.phone,
        password: registrationData.password,
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === "error") {
          setErrorText(response.data.message);
          console.log(response);
        } else {
          navigate("/");
        }
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="formContainerRegistr">
      <div className="formWrapperRegistr">
        <span className="titleRegist">Регистрация</span>
        <form className="formRegistr">
          <div className="name">
            <img src={Name} alt="" />
            <input
              type="text"
              placeholder="ФИО"
              name="fio"
              value={registrationData.fio}
              onChange={handleChangeRegister}
            />
          </div>
          <div className="email">
            <img src={Email} alt="" />
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={registrationData.email}
              onChange={handleChangeRegister}
            />
          </div>
          <div className="phone">
            <img src={Phone} alt="" />
            <input
              type="phone"
              placeholder="Телефон"
              name="phone"
              value={registrationData.phone}
              onChange={handleChangeRegister}
            />
          </div>
          <div className="password">
            <img src={Lock} alt="" />
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              name="password"
              id="pass"
              value={registrationData.password}
              onChange={handleChangeRegister}
              className="inputPassword"
            />
            <img
              src={Eye}
              alt={showPassword ? "Hide" : "Show"}
              onClick={togglePasswordVisibility}
              className="eyeIcon"
            />
          </div>
          <div className="passwordErrorBlock">
            <span className="passwordError">{errorPassword}</span>
          </div>
          <div className="errorMessage">{errorText}</div>
          <div className="checkboxContainer">
            <input type="checkbox" />
            <span>Я согласен с политикой конфиденциальности</span>
          </div>
          <button className="registrationBtn" onClick={handleButton}>
            Зарегистрироваться
          </button>
          <span className="infoRegistrSpan">
            Есть аккаунт?
            {
              <Link className="link" to={"/login"}>
                Войти
              </Link>
            }
          </span>
        </form>
      </div>
    </div>
  );
};

export default Registrations;
