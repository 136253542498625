import React from "react";
import "./goods.scss";
import { Card } from "react-bootstrap";
import testProduct from "../../image/menuItems/testProduct.png";
import bucketBtn from "../../image/menuItems/bucketBtn.svg"

function Goods() {
    return(
        <div className="goods">
            <span className="title">Отложенные товары</span>
            <div className="item">
                <Card style={{ width: "18rem" }}>
                    <Card.Img variant="top" src={testProduct} />
                    <Card.Body>
                        <Card.Title>WoodTec PS 45S PRO</Card.Title>
                        <Card.Text>
                            <div className="priceBox">
                                <span className="price">$300</span>
                                <span className="action">$200</span>
                            </div>
                            <button className="btnBucket">
                                <img src= {bucketBtn} alt=""/>
                            </button>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

export default Goods;