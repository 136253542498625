import React, { useContext, useState, useEffect } from "react";
import "./login.scss";
import { Link, useNavigate } from "react-router-dom";
import Email from "../../image/menuItems/email.svg";
import Lock from "../../image/menuItems/lock.svg";
import Eye from "../../image/menuItems/eye.svg";
import axios from "axios";
import { CustomContext } from "../../components/contexts/ProductUsersContext";
import { BASE_URL } from "../../utils/Constants";

const Login = () => {
  const { setUserPersonalData, setUserError, setUserProfile } =
    useContext(CustomContext);
  const [showPassword, setShowPassword] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorBoxShow, setErrorBoxShow] = useState(true);
  const [authData, setAuthData] = useState({ login: "", password: "" });
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserData = localStorage.getItem("userPersonalData");
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      setUserPersonalData(userData);
      navigate("/profile");
    }
  }, [setUserPersonalData, navigate]);

  const handleAuthChange = (event) => {
    setAuthData({ ...authData, [event.target.name]: event.target.value });
  };

  const logoutBtn = () => {
    axios.get(`${BASE_URL}/local/API/v0.1/user/logout/`);
    setUserPersonalData(null);
    setUserError(false);
    localStorage.removeItem("userPersonalData");
    navigate("/");
  };

  const handleButton = (event) => {
    event.preventDefault();
    axios
      .post(`${BASE_URL}/local/API/v0.1/user/auth/`, {
        login: authData.login,
        password: authData.password,
      })
      .then((response) => {
        if (response.data.status === "error") {
          setErrorText(response.data.message);
          setErrorBoxShow(false);
          return;
        }
        const userData = {
          userData: {
            id: response.data.userId,
            fio: response.data.userInformation.LAST_NAME,
            email: response.data.userInformation.EMAIL,
            phone: response.data.userInformation.PERSONAL_PHONE,
            companyName: response.data.userInformation.WORK_COMPANY,
          },
        };
        setUserError(true);
        setUserPersonalData(userData);
        localStorage.setItem("userPersonalData", JSON.stringify(userData));

        axios
          .get(`${BASE_URL}/local/API/v0.1/user/getProfilesList/`)
          .then((response) => {
            console.log(response);
            setUserProfile(response.data.data);
          });

        navigate("/profile");
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="content_block">
      <div className="formContainer1">
        <div className="formWrapper1">
          <span className="title">Авторизация</span>
          <form>
            <div className="email">
              <img src={Email} alt="" />
              <input
                type="email"
                placeholder="Email"
                name="login"
                value={authData.login}
                onChange={handleAuthChange}
              />
            </div>
            <div className="password">
              <img src={Lock} alt="" />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={authData.password}
                onChange={handleAuthChange}
              />
              <img
                src={Eye}
                alt={showPassword ? "Hide" : "Show"}
                onClick={togglePasswordVisibility}
                className="eyeIcon"
              />
            </div>
            <div className="buttons">
              <button onClick={handleButton}>Войти</button>
              {
                <Link className="link" to={"/registration"}>
                  {" "}
                  Зарегистрироваться{" "}
                </Link>
              }
            </div>
            <div
              className={`errorMessage ${errorBoxShow ? "display-none" : ""}`}
            >
              {errorText} <Link to="/restore">Восстановить пароль</Link>
              <button onClick={logoutBtn}>Выйти</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
