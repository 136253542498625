import React, { useContext } from "react";
import "./carts.scss";
import { Link } from "react-router-dom";
import { CustomContext } from "../../components/contexts/ProductUsersContext";
import { EmptyCart } from "../emptyCart/EmptyCart";
import { BASE_URL } from "../../utils/Constants";

function Carts() {
  const { order, removeBucket, incQuantity, decQuantity, clearBucket } =
    useContext(CustomContext);

  const totalPrice = order.reduce((sum, element) => {
    return sum + element.price * element.quantity;
  }, 0);

  console.log(order);

  if (order.length === 0) {
    return <EmptyCart />;
  } else {
    return (
      <>
        <div className="page_title">
          <h1>Корзина</h1>
        </div>

        <div className="basket">
          <div className="mainBox">
            {order.map((cartItem) => (
              <div className="carts" key={cartItem.id}>
                <div className="imageBox">
                  <img src={BASE_URL + cartItem.picture} alt="" />
                </div>
                <div className="title">
                  <h3>{cartItem.name}</h3>
                </div>
                <div className="priceBox">
                  {cartItem.price === cartItem.oldPrice ? (
                    <span className="action">{cartItem.oldPrice}</span>
                  ) : (
                    <>
                      <span className="price">{cartItem.oldPrice}</span>{" "}
                      <span className="action">{cartItem.price}</span>{" "}
                    </>
                  )}
                </div>
                <div className="amounts">
                  <button
                    className="btnMinus"
                    onClick={() =>
                      cartItem.quantity <= 1
                        ? removeBucket(cartItem.id)
                        : decQuantity(cartItem.id)
                    }
                  >
                    -
                  </button>
                  <span className="amount">{cartItem.quantity}</span>
                  <button
                    className="btnPlus"
                    onClick={() => incQuantity(cartItem.id)}
                  >
                    +
                  </button>
                </div>
                <div className="buttons">
                  <button
                    className="delete"
                    onClick={() => removeBucket(cartItem.id)}
                  >
                    <img src="{ imgDelete }" alt="" />
                    Удалить
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="checkout">
            <div className="titleSum">
              <div>Итого:</div>
              <div>{totalPrice} руб.</div>
            </div>
            <div className="buttonBox">
              <Link to={"/ordering"}>
                <button className="buy">
                  <img src="check.svg" alt="" />
                  Оформить заказ
                </button>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Carts;
