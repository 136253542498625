const About = () => {
  return (
<div className="content_block">

  <div className="page_title">
    <h1>О компании</h1>
  </div>

  <p>Компания СтанкиПро основана в 2018 году. В штате трудоустроены специалисты с десятилетним опытом подбора и поставок самого разнообразного оборудования.</p>
  <p>Являемся эксклюзивными и официальными дилерами крупного завода Woodtec, Stalex, Harvey Rus, Giperterm, Korvett, Comprag, Tapco и др.</p>
  <p>Из отзыва постоянного клиента: «Как замечательно, что есть компания, которая поддерживает всех производителей и может подобрать необходимое оборудование. Спасибо за высокую квалификацию!»</p>
  <p>Принимаем активное участие в региональных и всероссийских специализированных выставках в качестве участников. Посещаем обучающие семинары, не прекращая личностное и профессиональное развитие.</p>
  <p>Гарантируем индивидуальный подход. Наши клиенты спокойны, потому что мы берем на себя обязательства по подбору из массы предлагаемого оборудования необходимое именно под определенные задачи.</p>
  <p>Сотрудники нашей компании с улыбкой встретят каждого клиента. Ваши проекты мы обсудим в спокойной, комфортной, дружеской обстановке за чашечкой ароматного кофе.</p>
  <p>Компания предлагает клиентам не только новое оборудование. Реализована возможность подбора б/у оборудования, в том числе после капитального ремонта&nbsp;(бывшее в использовании оборудование полностью работоспособно и готово к эксплуатации).</p>

</div>
  );
};

export default About;
