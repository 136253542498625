import React, { useContext, useEffect } from "react";
import "./offers.css";
import "./offers.scss";
import { Carousel } from "react-bootstrap";
import { CustomContext } from "../contexts/ProductUsersContext";
import axios from "axios";
import he from "he";
import "bootstrap/dist/css/bootstrap.min.css";
import { BASE_URL } from "../../utils/Constants";

function Offers() {
  const { sliderData, setSlider } = useContext(CustomContext);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/local/API/v0.1/content/getTopSlides/`)
      .then((response) => {
        setSlider(response.data.slides);
      });
  }, []);

  return (
    <Carousel>
      {sliderData.map((slider) => (
        <Carousel.Item key={slider.FIELDS.ID}>
          <div className="content_block">
            <div className="titleBlock">
              <h1>
                {he.decode(slider.FIELDS.NAME).replace(/<br\s*\/?>/gi, " ")}
              </h1>
            </div>
            <img
              src={`${BASE_URL + slider.FIELDS.PREVIEW_PICTURE_SRC}`}
              alt=""
            />
            <Carousel.Caption>
              <span>{slider.FIELDS.PREVIEW_TEXT}</span>
            </Carousel.Caption>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default Offers;
