import React from "react";
import imgCart from "../../image/empty_cart.svg";
import { Link } from "react-router-dom";

function EmptyCart() {
  return (
    <div>
      <div className="page_title">
        <h1>Корзина</h1>
      </div>
      <div className="page_content center">
          <img src={imgCart} alt="Корзина пуста" className="empty-cart-image" />
          <p className="empty-cart-message">Ваша корзина пуста</p>
            <Link to="/" className="empty-cart-link">
              Вернуться к покупкам
            </Link>
      </div>
    </div>
  );
}

export { EmptyCart }; 
