import React, { useContext, useEffect } from "react";
import "./menu.scss";
import { CustomContext } from "../contexts/CatalogContext";
import hamburgerImg from "../../image/menuItems/hamburger.svg";
import axios from "axios";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../utils/Constants";

function Menu() {
  const { catalogData, setCatalog } = useContext(CustomContext);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/local/API/v0.1/shop/sections/get/`)
      .then((response) => {
        setCatalog(response.data.sections);
      });
  }, []);

  let menuTitle = [];
  const groupedData = {};

  catalogData.forEach((element) => {
    if (element.isHaveSubsections === true) {
      groupedData[element.id] = { parentTitle: element, childrenTitle: [] };
    } else {
      const parentTitle = groupedData[element.parentSectionId];
      if (parentTitle) {
        parentTitle.childrenTitle.push(element);
      }
    }
  });

  menuTitle = Object.values(groupedData).map(
    ({ parentTitle, childrenTitle }) => ({
      parentTitle,
      childrenTitle,
    })
  );

  return (
    <div className="menu">
      <div className="content_block">
        <div className="listProducts">
          <ul className="nav">
            <li className="navItem catalog">
              <Link to={"/"} className="navLink">
                <img src={hamburgerImg} alt="" className="icon" /> Каталог
              </Link>
              <div className="navSubmenuWrapper content_block">
                <div className="navSubmenu">
                  {menuTitle.map((data) => (
                    <div className="item" key={data.parentTitle.id}>
                      {/* <Link
                      to={`/category/${data.parentTitle.id}`}
                      className="parentItem"
                      key={data.parentTitle.id}
                    > */}
                      <span className="parentTitle">
                        {data.parentTitle.name}
                      </span>
                      {/* </Link> */}
                      {data.childrenTitle.map((child) => (
                        <Link
                          to={`/category/${child.id}`}
                          className="childItem"
                          key={child.id}
                        >
                          <span className="childTitle">{child.name}</span>
                        </Link>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </li>

            <li className="navItem">
              <Link to={"about"} className="navLink">
                О компании
              </Link>
            </li>
            <li className="navItem">
              <Link to={"popular"} className="navLink">
                Популярные товары
              </Link>
            </li>
            <li className="navItem">
              <Link to={"conditions"} className="navLink">
                Доставка и оплата
              </Link>
            </li>
            <li className="navItem">
              <Link to={"service"} className="navLink">
                Сервис
              </Link>
            </li>
            <li className="navItem">
              <Link to={"https://stanki05.ru/novosti"} className="navLink">
                Новости
              </Link>
            </li>
            <li className="navItem">
              <Link to={"contacts"} className="navLink">
                Контакты
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Menu;
