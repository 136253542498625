import React, { useEffect, useState, useContext } from "react";
import "./product.scss";
import he from "he";
import bucketBtn from "../../image/menuItems/bucketBtn.svg";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CustomContext } from "../../components/contexts/ProductUsersContext";
import { BASE_URL } from "../../utils/Constants";

function Product() {
  const { id } = useParams();
  const { addToBacked = Function.prototype } = useContext(CustomContext);
  const [product, setProduct] = useState();

  useEffect(() => {
    axios
      .post(`${BASE_URL}/local/API/v0.1/shop/products/getById/`, { pId: id })
      .then((response) => {
        setProduct(response.data.data);
      });
  }, []);

  return (
    <div className="content_block">
      <div className="product">
        <div className="main_info">
          <h3>{product?.NAME}</h3>
          <div className="productImg">
            <img src={`${BASE_URL + product?.PREVIEW_PICTURE}`} alt="" />
          </div>
          <div className="block-inform">
            <div className="textBox">
              {/* <span>{product?.previewText}</span> */}
              <span className="mainText">
                {product?.DETAIL_TEXT
                  ? he.decode(product.DETAIL_TEXT).replace(/<\/?p>/g, " ")
                  : ""}
              </span>
              <div className="infoText">
                {/* <span>Длина подвижного стола: 3200 мм</span>
              <span>Высота пропила: 90 мм</span>
              <span>Мощность: 6,25 кВт</span>
              <span>Вес: 940 кг</span> */}
              </div>
            </div>
            <div className="buyBox">
              <div className="priceBuyBox">
                {product?.PRICE === product?.OLD_PRICE ? (
                  <span className="actionPrice">{product?.OLD_PRICE} руб.</span>
                ) : (
                  <>
                    <span className="actualPrice">{product?.OLD_PRICE}</span>{" "}
                    <span className="actionPrice">{product?.PRICE} руб.</span>{" "}
                  </>
                )}
              </div>
              <div className="quantityBox">
                {/* <button>-</button>
              <input type="text" value={1} />
              <button>+</button> */}
              </div>
              <button
                className="btnBucketProd"
                onClick={() =>
                  addToBacked({
                    id: product.id,
                    name: product.name,
                    price: product.price,
                    oldPrice: product.oldPrice,
                    picture: product.picture,
                  })
                }
              >
                <img src={bucketBtn} alt="" /> В корзину
              </button>
            </div>
          </div>
        </div>
        <div className="block-inform__text">
          <div className="tabs">
            <ul>
              <li>Описание</li>
            </ul>
          </div>
          {/* <span>{product?.previewText}</span> */}
          <span className="mainText">
            {product?.DETAIL_TEXT
              ? he.decode(product.DETAIL_TEXT).replace(/<\/?p>/g, " ")
              : ""}
          </span>
          <div className="infoText">
            {/* <span>Длина подвижного стола: 3200 мм</span>
          <span>Высота пропила: 90 мм</span>
          <span>Мощность: 6,25 кВт</span>
          <span>Вес: 940 кг</span> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
