import React, { useContext, useEffect } from "react";
import "./categoryItems.scss";
import { Card } from "react-bootstrap";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { CustomContext } from "../../components/contexts/ProductUsersContext";
import { BASE_URL } from "../../utils/Constants";

function CategoryItems() {
  const { categoryProducts, setCategoryProducts, addToBacked } =
    useContext(CustomContext);
  const { id } = useParams();

  useEffect(() => {
    axios
      .post(`${BASE_URL}/local/API/v0.1/shop/section/products/get/`, {
        sectionId: id,
      })
      .then((response) => {
        setCategoryProducts(response.data.products);
      });
  }, [id]);

  console.log(categoryProducts);
  return (
    <div className="content_block">
      <div className="page_title">
        <h1>{categoryProducts[0]?.sectionName}</h1>
      </div>
      <div className="categoryItems">
        {categoryProducts.map((product) => (
          <Card style={{ width: "18rem" }} key={product.id}>
            <Card.Img variant="top" src={product.picture} />
            <Card.Body>
              <Card.Title>
                <Link to={`/product/${product.id}`}>{product.name}</Link>
              </Card.Title>
              <Card.Text>
                <div className="priceBox">
                  <span className="price">{product.price}</span>
                  <span className="action">{product.oldPrice}</span>
                </div>
                <button
                  className="btnBucket"
                  onClick={() =>
                    addToBacked({
                      id: product.id,
                      name: product.name,
                      price: product.price,
                      oldPrice: product.oldPrice,
                      picture: product.picture,
                      sku: product.SKU,
                    })
                  }
                >
                  Купить
                </button>
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default CategoryItems;
