import React, { useContext } from "react";
import "./history.scss";
import { CustomContext } from "../../components/contexts/ProductUsersContext";

function History() {
  const { historyOrder, userPersonalData } = useContext(CustomContext);

  return (
    <div className="history">
      <div className="mainTitle">
        <h1>История заказов</h1>
      </div>
      {historyOrder.map((history) => (
        <div className="contentHistory" key={history.name}>
          <ul className="historyList">
            <li>
              <div className="infoBox">
                <div className="info">
                  <p className="client">{userPersonalData.userData.fio}</p>
                  {history.products.map((productHistory) => (
                    <React.Fragment key={productHistory.id}>
                      <div className="historyBox">
                        <p className="order">{productHistory.name}</p>
                        <div className="products">
                          <img
                            className="imageHistory"
                            src={productHistory.picture}
                            alt=""
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="delivery">
                    <span className="timeTitle">Дата заказа:</span>
                    <time className="timeContent">
                      {history.orderDateCreate}
                    </time>
                    <div className="total">
                      <span className="totalTitle">Сумма заказа: </span>
                      <span className="totalText">
                        {history.products.reduce(
                          (sum, productHistory) =>
                            sum +
                            productHistory.price * productHistory.quantity,
                          0
                        )}{" "}
                        руб.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      ))}
    </div>
  );
}

export default History;
