import React from "react";
import { Link, useLocation } from "react-router-dom";
import { sidebarMenu, iconMap } from "../../utils/Constants"; // Ensure the correct path
import "./sidebar.scss";

function Sidebar() {
  const location = useLocation();
  const isActive = (url) => location.pathname === url;

  return (
    <div className="sidebar">
      {sidebarMenu.map((menu) => (
        <div
          className={`item ${isActive(menu.url) ? "active" : ""}`}
          key={menu.id}
        >
          <Link to={menu.url}>
            {iconMap[menu.icon]}
            <span className="title">{menu.title}</span>
          </Link>
        </div>
      ))}
    </div>
  );
}

export default Sidebar;
