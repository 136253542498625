import React from "react";
import "./edit.scss";
import fioIMG from "../../image/menuItems/fio.svg";
import emailIMG from "../../image/menuItems/email.svg";
import adressIMG from "../../image/menuItems/adress.svg";
import phoneIMG from "../../image/menuItems/phone.svg";
import companyIMG from "../../image/menuItems/company.svg";

const Edit = () => {
  return (
    <div className="content_block">
      <div className="edit">
        <span className="title">Редактирование</span>
        <div className="name">
          <img src={fioIMG} alt=""></img>
          <label htmlFor="name">Имя:</label>
          <input type="name"></input>
        </div>
        <div className="email">
          <img src={emailIMG} alt=""></img>
          <label htmlFor="name">Email:</label>
          <input type="email"></input>
        </div>
        <div className="adress">
          <img src={adressIMG} alt=""></img>
          <label htmlFor="adress">Адрес:</label>
          <input type="adress"></input>
        </div>
        <div className="phone">
          <img src={phoneIMG} alt=""></img>
          <label htmlFor="phone">Номер телефона:</label>
          <input type="phone"></input>
        </div>
        <div className="company">
          <img src={companyIMG} alt=""></img>
          <label htmlFor="company">Название компании:</label>
          <input type="company"></input>
        </div>
        <button>Обновить данные</button>
      </div>
    </div>
  );
};

export default Edit;
