import React, { createContext, useReducer } from "react";
import { productUsersReducer } from "../reducers/ProductsUsersReducer";

export const CustomContext = createContext();

const initialState = {
  productData: [],
  originalProductData: [], //дополнительное состояние для хранения исходных данных
  order: [],
  recommendedProductData: [],
  popularProductData: [],
  categoryProducts: [],
  userAuth: [],
  userPersonalData: [],
  userError: "",
  userProfile: [],
  sliderData: [],
  historyOrder: [],
};

export const ProductUsersContext = ({ children }) => {
  const [value, dispatch] = useReducer(productUsersReducer, initialState);

  value.setProducts = (data) => {
    dispatch({ type: "SET_PRODUCTS", payload: data });
  };

  value.setOriginalProductData = (data) => {
    dispatch({ type: "SET_ORIGINAL_PRODUCT", payload: data });
  };

  value.addToBacked = (item) => {
    dispatch({ type: "ADD_TO_BUCKET", payload: item });
  };

  value.incQuantity = (itemId) => {
    dispatch({ type: "INC_QUANTITY", payload: { id: itemId } });
  };

  value.decQuantity = (itemId) => {
    dispatch({ type: "DEC_QUANTITY", payload: { id: itemId } });
  };

  value.removeBucket = (itemId) => {
    dispatch({ type: "REMOVE_BUCKET", payload: { id: itemId } });
  };

  value.clearBucket = () => {
    dispatch({ type: "CLEAR_BUCKET" });
  };

  value.setRecommendedProductData = (data) => {
    dispatch({ type: "SET_RECOMMENDED_PRODUCT_DATA", payload: data });
  };

  value.setPopularProductData = (data) => {
    dispatch({ type: "SET_POPULAR_PRODUCT_DATA", payload: data });
  };

  value.setCategoryProducts = (data) => {
    dispatch({ type: "SET_CATEGORY_PRODUCTS", payload: data });
  };

  value.setUserAuth = (data) => {
    dispatch({ type: "SET_USER_AUTH", payload: data });
  };

  value.setUserPersonalData = (data) => {
    dispatch({ type: "SET_USER_PERSONAL_DATA", payload: data });
  };

  value.setUserError = (data) => {
    dispatch({ type: "SET_USER_ERROR", payload: data });
  };

  value.setUserProfile = (data) => {
    dispatch({ type: "SET_USER_PROFILE", payload: data });
  };

  value.setSlider = (data) => {
    dispatch({ type: "SET_SLIDER_DATA", payload: data });
  };

  value.setHistoryOrder = (data) => {
    dispatch({ type: "SET_HISTORY_ORDER", payload: data });
  };

  return (
    <CustomContext.Provider value={value}>{children}</CustomContext.Provider>
  );
};
