export function catalogReducer(state, { type, payload }) {
  switch (type) {
    case "SET_CATALOG":
      return {
        ...state,
        catalogData: payload || [],
      };

    default:
      return state;
  }
}
