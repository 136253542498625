import React, { useContext, useState } from "react";
import "./ordering.scss";
import card from "../../image/menuItems/card.svg";
import bucketBtn from "../../image/menuItems/bucketBtn.svg";
import { CustomContext } from "../../components/contexts/ProductUsersContext";
import { EmptyCart } from "../emptyCart/EmptyCart";
import axios from "axios";
import { BASE_URL } from "../../utils/Constants";

function Ordering() {
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const {
    order,
    removeBucket,
    incQuantity,
    decQuantity,
    userPersonalData,
    userProfile,
  } = useContext(CustomContext);

  const totalPrice = order.reduce((sum, element) => {
    return sum + element.price * element.quantity;
  }, 0);

  const orderItems = order.map((item) => {
    const itemPrice = item.price * item.quantity; // Рассчитываем сумму для текущего товара
    return {
      ...item,
      totalItemPrice: itemPrice, // Добавляем сумму в объект товара
    };
  });

  const handleClientChange = (event) => {
    setSelectedClient(event.target.value);
  };

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const handleButtonPay = () => {
    const products = order.map((item) => {
      return {
        productId: item.id,
        quantity: item.quantity,
      };
    });

    axios
      .post(`${BASE_URL}/local/API/v0.1/order/make/`, {
        products: products,
        userId: userPersonalData.userData.id,
        userComment: "",
      })
      .then((resp) => {
        console.log(resp);
      });
  };

  if (order.length === 0) {
    return <EmptyCart />;
  } else {
    return (
      <div className="content_block">
        <div className="mainBox">
          <div className="page_title">
            <h1>Оформление заказа</h1>
          </div>
          <div className="orderBox">
            <div className="info">
              {userPersonalData.length === 0 ? (
                <div className="errorBox">
                  <span>Для продолжения Вам нужно авторизоваться</span>
                </div>
              ) : (
                <>
                  <div className="contacts">
                    <p className="name">{userPersonalData.userData.fio}</p>
                    <p className="email">{userPersonalData.userData.email}</p>
                  </div>

                  <div className="profile">
                    <label htmlFor="clientSelect">
                      Выберите профиль клиента:
                    </label>
                    <select
                      id="clientSelect"
                      className="clientSelect"
                      value={selectedClient}
                      onChange={handleClientChange}
                    >
                      <option value="">Выберите клиента</option>
                      {userProfile.map((profile) => (
                        <option value={profile.name}>{profile.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="adress">
                    <span className="adressTitle">Адрес доставки</span>
                    <p className="adressLine">
                      {
                        userProfile.find(
                          (profile) => profile.name === selectedClient
                        )?.deliveryAddress
                      }
                    </p>
                  </div>

                  <div className="delivery">
                    <p className="timeTitle">Время доставки</p>
                    <time className="time">45 мин</time>
                  </div>
                  <div className="payment">
                    <img src={card} alt=""></img>
                    <label htmlFor="paymentMethodSelect">
                      Выберите способ оплаты:
                    </label>
                    <select
                      id="paymentMethodSelect"
                      className="paymentMethodSelect"
                      value={selectedPaymentMethod}
                      onChange={handlePaymentMethodChange}
                    >
                      <option value="Наличные">Наличные</option>
                      <option value="Перевод">Перевод</option>
                      <option value="Безналичный расчет">
                        Безналичный расчет
                      </option>
                    </select>
                  </div>

                  <div className="finish">
                    <span className="money">{totalPrice}</span>
                    <button className="pay" onClick={handleButtonPay}>
                      Перейти к оплате
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="list">
              {orderItems.map((items) => (
                <div className="item">
                  <div className="imageBox">
                    <img src={BASE_URL + items.picture} alt="" />
                  </div>
                  <div className="infoBox">
                    <div className="title">
                      <div>{items.name}</div>
                    </div>
                    <div className="priceBox">
                      <span className="price">{items.totalItemPrice}</span>
                      <span className="action">{items.oldPrice}</span>
                    </div>
                    <div className="amounts">
                      <button
                        className="btnMinus"
                        onClick={() =>
                          items.quantity <= 1
                            ? removeBucket(items.id)
                            : decQuantity(items.id)
                        }
                      >
                        -
                      </button>
                      <span className="amount">{items.quantity}</span>
                      <button
                        className="btnPlus"
                        onClick={() => incQuantity(items.id)}
                      >
                        +
                      </button>
                    </div>
                    <div className="buttons">
                      <button
                        className="delete"
                        onClick={() => removeBucket(items.id)}
                      >
                        <img src={bucketBtn} alt="" />
                        Удалить заказ
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div>{totalPrice} руб.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Ordering;
