import React, { useContext, useEffect, useState } from "react";
import "./items.scss";
import { CustomContext } from "../contexts/ProductUsersContext";
import { Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../utils/Constants";

function Items() {
  const {
    productData,
    setProducts,
    addToBacked = Function.prototype,
    setOriginalProductData,
    order,
  } = useContext(CustomContext);

  const [addedProducts, setAddedProducts] = useState([]);
  const [loadedProductsCount, setLoadedProductsCount] = useState(10);

  const loadMoreProducts = () => {
    setLoadedProductsCount((prevCount) => prevCount + 10);
  };

  useEffect(() => {
    axios
      .post(`${BASE_URL}/local/API/v0.1/shop/products/get/`, {
        limit: loadedProductsCount,
      })
      .then((response) => {
        setProducts(response.data.products);
        setOriginalProductData(response.data.products);
      });
  }, [loadedProductsCount]);

  useEffect(() => {
    const addedProductIds = order.map((product) => product.id);
    setAddedProducts(addedProductIds);
  }, [order]);

  return (
    <div className="items-container">
      <div className="content_block">
        <div className="top_block">
          <h3>Лучшие предложения</h3>
        </div>
        <div className="items">
          {productData.map((product) => (
            <Card key={product.id}>
              <div className="cardimgbox">
                <Card.Img
                  variant="top"
                  src={`${BASE_URL + product.detailPicture}`}
                />
              </div>
              <Card.Body>
                <Card.Title>
                  <Link to={`product/${product.id}`}>{product.name}</Link>
                </Card.Title>
                <Card.Text>
                  <div className="priceBox">
                    {product.price === product.oldPrice ? (
                      <span className="action">{product.oldPrice} р</span>
                    ) : (
                      <>
                        <span className="price">{product.oldPrice} р</span>{" "}
                        <span className="action">{product.price} р</span>{" "}
                      </>
                    )}
                  </div>
                  <button
                    className={`btnBucket ${
                      addedProducts.includes(product.id) ? "added" : ""
                    }`}
                    onClick={() => {
                      console.log("Добавление в корзину", product);

                      addToBacked({
                        id: product.id,
                        name: product.name,
                        price: product.price,
                        oldPrice: product.oldPrice,
                        picture: product.detailPicture,
                        sku: product.SKU,
                      });
                    }}
                    disabled={addedProducts.includes(product.id)}
                  >
                    {/* {addedProducts.includes(product.id)
                      ? "В корзине"
                      : "В корзину"} */}
                  </button>
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
        <div className="load-more">
          <button className="load-more-btn" onClick={loadMoreProducts}>
            Загрузить ещё
          </button>
        </div>
      </div>
    </div>
  );
}

export default Items;
