import React from "react";
import "./profile.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { Outlet } from "react-router-dom";

function Profile() {
  return (
    <div className="profile">
      <div className="sideBarContainer">
        <Sidebar />
      </div>
      <div className="sidebarContent">
        <Outlet />
      </div>
    </div>
  );
}

export default Profile;
