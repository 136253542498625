import React from "react";
import "./footerbar.scss";
import Logo from "../../image/logo-stanki05-footer.png";
import Ins from "../../image/ins.jpg";

function Footerbar() {
  return (
    <div className="footerbar">
      <div className="content_block">
        <div className="row">
          <div className="col-md-3 col-sm-3">
            <div className="logo">
              <img src={Logo} alt="" />
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <div className="footer-title">
              <h5>Информация</h5>
            </div>
            <div className="footer-content">
              <ul>
                <li>
                  <a href="https://stanki05.ru/kontakty">Каталог</a>
                </li>
                <li>
                  <a href="/about">О компании</a>
                </li>
                <li>
                  <a href="/conditions">Доставка и оплата</a>
                </li>
                <li>
                  <a href="/service">Сервис</a>
                </li>
                <li>
                  <a href="/contacts">Контакты</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <div className="footer-title">
              <h5>Контакты</h5>
            </div>
            <ul>
              <li>
                <p className="footer__address">
                  <span>
                    г. Махачкала, ул. Булача, 17д
                    <br />
                    г. Махачкала, ул. Аметхана Султана, 314
                  </span>
                </p>
              </li>
              <li>
                <div>
                  <a href="tel:8 8722 777 711" className="footer__num_bold">
                    8 8722 777 711
                  </a>
                  <br />
                  <a href="tel:+7 (963) 423-16-93" className="">
                    +7 (963) 423-16-93
                  </a>
                </div>
              </li>
              <li>
                <div className="footer__whatsapp">
                  <a
                    href="https://api.whatsapp.com/send?phone=79634231693"
                    className="ion-social-whatsapp"
                  >
                    <span>Написать в WhatsApp</span>
                  </a>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-md-3 col-sm-3">
            <a href="https://www.instagram.com/stanki.pro/" className="ins">
              <img src={Ins} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footerbar;
