import React from "react";
import "./home.scss";
import Items from "../../components/items/Items";
import Offers from "../../components/offers/Offers";

function Home() {
  return (
    <div className="home">
      <Offers />
      <Items />
    </div>
  );
}

export default Home;
